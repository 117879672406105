<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isExpand = computed(() => store.state.Config.isExpand);
</script>

<template>
  <div id="dining">
    <div :class="['right-wp', isExpand ? 'expand' : '']">
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#dining {
  min-height: calc(100vh - 60px);
  display: flex;
  .right-wp {
    flex: 1;
    width: calc(100vw - 150px);
    background-color: var(--theme-bg-color);
  }
  .right-wp.expand {
    width: calc(100vw - 250px);
  }
}
</style>
